.contactimg{
    width: 30rem;
    height: 35rem;
    border-radius: 15rem 15rem 0 0;
 
}
.card1{
    border-radius: 10px;
    transition: all 300ms ease-in;
}
.card1:hover{
    box-shadow: 5px 5px 10px lightblue;
    transform: scale(1.1);

}
.conticon{
    padding: 5px;
    background: #eeeeff;
    border-radius: 5px;
    color:rgb(69, 69, 245);
   font-size: 30px;
}