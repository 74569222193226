.valueimg {
    width: 30rem;
    height: 35rem;
    border-radius: 15rem 15rem 0 0;
}

.accordion {
    margin-top: 2rem;
}

.accordionItem {
    background-color: white;
    border: 0.8px solid rgba(128, 128, 128, 0.143);
    border-radius: 8px;
    margin-bottom: 20px;
}


.accodionButton {
    background: white;
    padding: 1rem;
    width: 100%;
}

.icon {
    padding: 10px;
    width: 40px;
    background: #eeeeff;
    border-radius: 5px;
    color: blue;
}