*{
    box-sizing: border-box;
}
.bannerImg{
    width: 30rem;
    height: 35rem;
    border-radius: 15rem 15rem 0 0; 
    border: 8px solid rgba(255,255,255,0.12);
}
.searchbox{
    background-color: white;
    border-radius: 10px;
    width: 100%;
    
}
.searchinput{
    border:none;
    outline: none;
   width: 100%;
   margin-top: 10px;
   padding: 5px;
}
.searchbut{
    margin-top: 12px;
}
.icona{
    margin-top: 5px;
}
@media screen and (min-Width:769px) and (max-width:1024px) {
    .bannerImg{
        margin-left: 50px;
    }

}
@media screen and (min-Width:1025px) and (max-width:1440px) {
    .bannerImg{
        margin-left: 150px;
    }

}
@media screen and (min-Width:1441px) and (max-width:3500px) {
    .bannerImg{
        margin-left: 250px;
    }

}
