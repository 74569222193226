.rescard {
    gap: 0.6rem;
    padding: 1rem;
    border-radius: 10px;
    max-width: max-content;
    margin: auto;
    transition: all 300ms ease-in;
}

.rescard:hover {
    scale: 1.025;
    cursor: pointer;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0)0%, rgba(136, 160, 255, 0.46)217.91%);
    box-shadow: 0px 72px 49px -51px rgba(136, 160, 255, 0.21);
}
.swipbut{
    border-radius: 10px;
    border: 0;
    transition: all 200ms ease-in;
    box-shadow: 0px 2px 5px rgb(109, 108, 108);
}
.swipbut:hover{
    color:green;
    scale: 1.025;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0)10%, rgba(136, 160, 255, 0.46)217.91%);
}