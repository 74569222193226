.bg{
    border-radius: 15px;
}
.gettext{
    font-size: 40px;
}
.getstartbtn{
    border: 1px solid white;
    background-color: rgb(84, 135, 245);
    transition: all 300ms ease-in;
}
.getstartbtn:hover{
    transform: scale(1.1);
    background-color: rgb(84, 135, 245);
    border:2px solid white;
}
