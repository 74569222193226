.compimg1{
    height: 110px;
}
.compimg2{
    height: 100px;
}
.compimg3{
    width: 150px;
    height: 110px;
}
.compimg4{
    
    height: 100px;
}